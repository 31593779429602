import { JobStyle } from "@headbot/library";
import * as React from "react";
import { createContext, useCallback, useEffect, useState } from "react";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { SessionStorageKey } from "../../../services/Constants/SessionStorageKey";
import { IFileInput } from "../../atoms/FileUploader/FileUploader";
import { Stage2 } from "./Stages/Stage2/Stage2";
import { Stage3 } from "./Stages/Stage3/Stage3";
import { Stage4 } from "./Stages/Stage4/Stage4";

const stages: Array<JSX.Element> = [<Stage2 key="stage2" />, <Stage3 key="stage3" />, <Stage4 key="stage4" />];
const [initialStage] = stages;

interface ICreateGuestJobContextProps {
    readonly activeStage: JSX.Element;

    /** Each set of images should represent one face */
    readonly images: Record<string, Array<IFileInput>>;
    readonly gender: string;
    readonly guestJobId: string | null;
    readonly style: JobStyle;
    readonly setGuestJobId: (gender: string) => void;
    readonly setGender: (gender: string) => void;
    readonly onContinue: () => void;
    readonly onBack: () => void;
    readonly setImages: (images: Record<string, Array<IFileInput>>) => void;
    readonly reset: () => void;
}

const defaultStyle = JobStyle.Buff;

export const CreateGuestJobContext = createContext<ICreateGuestJobContextProps>({
    activeStage: initialStage,
    images: {},
    gender: "unspecified",
    guestJobId: null,
    style: defaultStyle,
    onBack: () => {},
    onContinue: () => {},
    setImages: (_images: Record<string, Array<IFileInput>>) => {},
    setGender: (_gender: string) => {},
    setGuestJobId: (_guestJobId: string) => {},
    reset: () => {},
});

interface IContextProps {
    readonly jobStyle: JobStyle;
}

export const CreateGuestJobContextProvider: React.FC<React.PropsWithChildren<IContextProps>> = ({ children, jobStyle }) => {
    const [currentStage, setCurrentStage] = useState<number>(0);
    const [images, setImages] = useState<Record<string, Array<IFileInput>>>({});
    const [gender, setGender] = useState<string>("unspecified");
    const [guestJobId, setGuestJobId] = useSessionStorage(SessionStorageKey.GuestJobId, null);
    const [style] = useState<JobStyle>(jobStyle);
    const onContinue = useCallback(() => {
        setCurrentStage(currentStage + 1);
    }, [currentStage, setCurrentStage]);

    const onBack = useCallback(() => {
        setCurrentStage(currentStage - 1);
    }, [currentStage, setCurrentStage]);

    const activeStage = stages[currentStage];
    useEffect(() => {
        if (guestJobId !== null) {
            const finalStageIndex = stages.length - 1;
            setCurrentStage(finalStageIndex);
        }
    }, [guestJobId]);

    const reset = useCallback(() => {
        setCurrentStage(0);
        setImages({});
        setGender("unspecified");
        setGuestJobId(null);
    }, [setCurrentStage, setImages, setGender, setGuestJobId]);
    return (
        <CreateGuestJobContext.Provider
            value={{
                activeStage,
                gender,
                guestJobId,
                images,
                style,
                onBack,
                onContinue,
                setGender,
                setGuestJobId,
                setImages,
                reset,
            }}
        >
            {children}
        </CreateGuestJobContext.Provider>
    );
};
