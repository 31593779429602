import * as React from "react";
import styled from "styled-components";
import { Label, Radio } from "./RadioButtonGroup.styles";

const Wrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    readonly label: string;
    readonly id: string;
    readonly error?: boolean;
    readonly disabled?: boolean;
}

export const RadioButton: React.FC<IProps> = ({ label, id, disabled, checked, ...rest }) => {
    return (
        <Wrapper>
            <Radio id={id} type="radio" disabled={disabled} checked={checked} {...rest} />
            <Label htmlFor={id} disabled={disabled}>
                <span>{label}</span>
            </Label>
        </Wrapper>
    );
};
