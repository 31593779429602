export type PromiseFunction = () => Promise<void>;

export const executePromisesSequentially = async (promises: PromiseFunction[]): Promise<void[]> => {
    const results = await promises.reduce(async (previousResultsPromise: Promise<Array<void>>, currentPromise: PromiseFunction) => {
        const previousResults = await previousResultsPromise;
        const currentResult = await currentPromise();
        return [...previousResults, currentResult];
    }, Promise.resolve([]));

    return results;
};
