import styled from "styled-components";
import { Color } from "../../../../../layout/GlobalStyles/Color";
import { Dimensions } from "../../../../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../../../../layout/GlobalStyles/Viewport";

export const Container = styled.div`
    padding: ${Dimensions.GridUnit * 2}px;
    box-sizing: border-box;
    border-top: 1px solid ${Color.Neutral20};
    @media screen and (max-width: ${Viewport.Medium}px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${Color.White100};
        z-index: 100;
    }
`;

export const MediumSpacer = styled.div`
    height: 0px;
    display: block;
    @media screen and (max-width: ${Viewport.Medium}px) {
        height: 157px;
    }
`;
