import { JobStyle, TelemetryEvent } from "@headbot/library";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../../services/Constants/Constants";
import { telemetry } from "../../../../../services/Telemetry/Telemetry";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { CreateGuestJobContext } from "../../CreateGuestJobContext";
import { CenteredH1, CenteredP } from "../Stages.styles";
import { MultiSelector } from "./MultiSelector";

export const Stage2: React.FC = () => {
    const [t] = useTranslation();
    const { onContinue, images, style } = useContext(CreateGuestJobContext);

    const imageCount = Object.values(images).reduce((acc, imageSet) => acc + imageSet.length, 0);

    const faceKeys = useMemo(() => (style === JobStyle.Couple ? ["face0", "face1"] : ["face0"]), [style]);
    useEffect(() => {
        telemetry.Log(TelemetryEvent.CreateJobStage2);
    }, []);
    const facesWithImages = Object.values(images).reduce((acc, imageSet) => {
        if (imageSet.length > 0) {
            return acc + 1;
        }
        return acc;
    }, 0);
    const isContinueDisabled = imageCount < Constants.MinimumRequiredImages || facesWithImages !== faceKeys.length;

    return (
        <>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 1 })}</CenteredP>

                <CenteredH1>{t("createJobPage.stage2.uploadPhotos")}</CenteredH1>
                <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                        <div className="shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                                For the best results, upload multiple photos. More images help us create a more accurate and personalized
                                output!
                            </p>
                        </div>
                    </div>
                </div>
                <MultiSelector imageKeys={faceKeys} />
                <PrimaryButton onClick={onContinue} type="button" disabled={isContinueDisabled}>
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </div>
        </>
    );
};
