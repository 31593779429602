import styled from "styled-components";
import { P } from "../../../../atoms/P/P";
import { Dimensions } from "../../../../layout/GlobalStyles/Dimensions";

export const UniqueImages = styled(P)``;

export const BannerRoot = styled.div`
    display: "flex";
    align-items: "center";
`;

export const Price = styled(P)`
    color: "black";
    font-size: "16px";
    margin-right: ${Dimensions.GridUnit}px;
`;
