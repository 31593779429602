import * as React from "react";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FileUploader, IFileInput } from "../../../../atoms/FileUploader/FileUploader";
import { CreateGuestJobContext } from "../../CreateGuestJobContext";
import { FileInputPreview } from "./FileInputPreview/FileInputPreview";

interface IProps {
    readonly imageKey: string;
    readonly uploadLabel?: string;
    readonly uploadTitle?: string;
}

export const SingleSelector: React.FC<IProps> = ({ uploadTitle, imageKey, uploadLabel }) => {
    const [t] = useTranslation();

    const { images, setImages } = useContext(CreateGuestJobContext);

    const onImageSelectionUpdated = useCallback(
        (newImages: Array<IFileInput>) => {
            const updatedImages = { ...images, [imageKey]: newImages };
            setImages(updatedImages);
        },
        [setImages, images, imageKey]
    );

    const onImageSelectionChanged = useCallback(
        (files: Array<IFileInput>) => {
            const previousSet = images[imageKey] ?? [];
            const updatedImageRecord = { ...images, [imageKey]: [...previousSet, ...files] };
            setImages(updatedImageRecord);
        },
        [imageKey, images, setImages]
    );

    const onImageRemoved = useCallback(
        (removedIndex: number) => {
            const updatedImages = [...images[imageKey]];
            updatedImages.splice(removedIndex, 1);
            onImageSelectionUpdated(updatedImages);
        },
        [images, imageKey, onImageSelectionUpdated]
    );

    const resolvedLabel = uploadLabel ?? t("createJobPage.stage2.uploadFileLabel");

    const imagesForThisKey = images[imageKey] ?? [];

    return (
        <>
            <FileUploader
                title={uploadTitle}
                id={imageKey}
                label={resolvedLabel}
                onFilesLoaded={onImageSelectionChanged}
                multiple={true}
                accept="image/*"
            />
            <FileInputPreview images={imagesForThisKey} onImageRemoved={onImageRemoved} />
        </>
    );
};
