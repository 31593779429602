import { TrashIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    readonly url: string;
    readonly imageIndex: number;
    readonly onImageRemoved: (removedIndex: number) => void;
}

export const SelectedImage: React.FC<IProps> = ({ url, imageIndex, onImageRemoved }) => {
    const [t] = useTranslation();
    const onRemoveClicked = useCallback(() => {
        onImageRemoved(imageIndex);
    }, [imageIndex, onImageRemoved]);
    return (
        <li key={url} className="relative">
            <div className="overflow-hidden rounded-lg bg-gray-100 shadow">
                <div className="px-4 py-5 sm:p-6">
                    {/* Container for both image and button */}
                    <div className="w-full max-w-xs mx-auto">
                        <img alt="" src={url} className="mx-auto pointer-events-none object-cover select-none" />
                        <button
                            onClick={onRemoveClicked}
                            type="button"
                            className="mt-4 w-full inline-flex items-center justify-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <TrashIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                            {t("components.fileInputPreview.remove")}
                        </button>
                    </div>
                </div>
            </div>
        </li>
    );
};
