import * as React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IFileInput } from "../../../../../atoms/FileUploader/FileUploader";
import { SelectedImage } from "./SelectedImage/SelectedImage";

interface IProps {
    readonly images: Array<IFileInput> | undefined;
    readonly onImageRemoved: (removedIndex: number) => void;
}

export const FileInputPreview: React.FC<IProps> = ({ images, onImageRemoved }) => {
    const [t] = useTranslation();
    const imageList = useMemo(() => Object.values(images ?? {}).flat(), [images]);
    const imageCount = imageList.length;
    const selectedTextLocale =
        imageCount === 1
            ? t("components.fileInputPreview.totalSelectedSingular", { imageCount })
            : t("components.fileInputPreview.totalSelectedPlural", { imageCount });

    const previewUrls = useMemo(() => {
        const urls = imageList.map((fileInput) => URL.createObjectURL(fileInput.data));

        return urls;
    }, [imageList]);

    useEffect(() => {
        return () => {
            previewUrls.forEach((url) => URL.revokeObjectURL(url));
        };
    }, [previewUrls]);

    const imagePreviews = previewUrls.map((url, index) => {
        return <SelectedImage key={url} url={url} imageIndex={index} onImageRemoved={onImageRemoved} />;
    });

    return (
        <div>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 mb-4">
                <span className="mb-4 inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                    {selectedTextLocale}
                </span>
                <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                    {imagePreviews}
                </ul>
            </div>
        </div>
    );
};
