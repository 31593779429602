import { ProductID } from "@headbot/library";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { H3 } from "../../../../atoms/H3/H3";
import { P } from "../../../../atoms/P/P";
import { IProduct, PackageSelector } from "../../../../atoms/PackageSelector/PackageSelector";
import { PaypalGuestJobPurchase } from "../../../PaypalGuestJobPurchase/PaypalGuestJobPurchase";
import { AnswerContainer, CenteredH1, CenteredP, PricesContainer, Root } from "../Stages.styles";
import { FreeTierOptions } from "./FreeTierOptions/FreeTierOptions";
import { Container, MediumSpacer } from "./FreeTierOptions/FreeTierOptions.style";
import { Prices } from "./Prices";

export const Stage4: React.FC = () => {
    const [t] = useTranslation();
    const [chosenProductID, setChosenProductID] = useState<ProductID | null>(null);

    const isPackageSelected = chosenProductID !== null;
    const isFreePackageSelected = chosenProductID === ProductID.Free;

    const packages: Array<IProduct> = [
        {
            productID: ProductID.Free,
            name: "Free",
            features: ["1 AI pic", "Headbot watermark", "512 x 512 resolution"],
        },
        {
            productID: ProductID.Starter,
            name: "Starter",
            features: ["25 AI pics", "Headbot watermark", "512 x 512 resolution"],
        },
        {
            productID: ProductID.Basic,
            name: "Basic",
            features: ["100+ AI pics", "No watermark", "512 x 512 resolution"],
        },
        {
            productID: ProductID.Premium,
            name: "Premium",
            features: ["100+ AI pics", "No watermark", "4k resolution suitable for printing"],
        },
    ];

    return (
        <>
            <Root>
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 3 })}</CenteredP>
                <CenteredH1>{t("createJobPage.stage4.selectPackage")}</CenteredH1>
                <PackageSelector onSelectProduct={setChosenProductID} selectedProductID={chosenProductID} packages={packages} />
                {isPackageSelected && isFreePackageSelected && <FreeTierOptions />}
                {isPackageSelected && isFreePackageSelected === false && (
                    <>
                        <MediumSpacer />
                        <Container>
                            <CenteredH1>{t("createJobPage.stage4.checkout")}</CenteredH1>
                            <AnswerContainer>
                                <H3>{t("createJobPage.stage4.WhyDoIHaveToPay")}</H3>
                                <P>{t("createJobPage.stage4.WhyDoIHaveToPayAnswer")}</P>
                            </AnswerContainer>
                            <PricesContainer>
                                <Prices discountRate={0} productID={chosenProductID} />
                            </PricesContainer>
                            <PaypalGuestJobPurchase couponCode={null} productID={chosenProductID} />
                        </Container>
                    </>
                )}
            </Root>
        </>
    );
};
