import { ProductID, TelemetryEvent, getCouponOrderSessionPayload } from "@headbot/library";
import { navigate } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePostLinkOrder } from "../../../../../../hooks/api/order/usePostLinkOrder";
import { useAuth } from "../../../../../../hooks/useAuth";
import { useSessionStorage } from "../../../../../../hooks/useSessionStorage";
import { HeadbotRoute } from "../../../../../../services/Constants/HeadbotRoute";
import { SessionStorageKey } from "../../../../../../services/Constants/SessionStorageKey";
import { telemetry } from "../../../../../../services/Telemetry/Telemetry";
import { PrimaryButton } from "../../../../../atoms/Button/PrimaryButton";
import { Container } from "./FreeTierOptions.style";

export const FreeTierOptions: React.FC = () => {
    const [t] = useTranslation();
    const [guestJobId, setGuestJobId] = useSessionStorage(SessionStorageKey.GuestJobId, null);
    const [_orderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);

    const { authToken, isValid } = useAuth();

    const onOrderSuccessfullyLinked = async () => {
        setGuestJobId(null);
        await telemetry.Log(TelemetryEvent.SuccessfulConversion, { product: ProductID.Free, conversionValue: 0, currency: "USD" });
    };

    const linkOrder = usePostLinkOrder(onOrderSuccessfullyLinked);

    const onGenerateClicked = async () => {
        const payload = getCouponOrderSessionPayload("VETS2024", ProductID.Free, guestJobId ?? "");
        if (isValid && authToken !== null) {
            await linkOrder.mutation.mutateAsync(payload);
            navigate(HeadbotRoute.Dashboard);
            return;
        }

        const payloadString = JSON.stringify(payload);
        setOrderDetails(payloadString);
        navigate(HeadbotRoute.Signup);
    };

    return (
        <Container>
            <PrimaryButton onClick={onGenerateClicked} type="button">
                {t("createJobPage.stage4.generateButton")}
            </PrimaryButton>
        </Container>
    );
};
