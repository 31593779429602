import * as React from "react";
import { Price } from "./Stage4.styles";

interface IProps {
    readonly strikethrough: boolean;
}

export const BasePrice: React.FC<React.PropsWithChildren<IProps>> = ({ children, strikethrough }) => {
    if (strikethrough) {
        return (
            <Price>
                <s>{children}</s>
            </Price>
        );
    }
    return <Price>{children}</Price>;
};
