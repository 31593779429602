import { ProductID, TelemetryEvent } from "@headbot/library";
import { CheckIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../functions/classNames";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { Prices } from "../../molecules/CreateJob/Stages/Stage4/Prices";

interface IProps {
    readonly onSelectProduct: (productID: ProductID) => void;
    readonly selectedProductID: string | null;
    readonly packages: Array<IProduct>;
}

export interface IProduct {
    readonly productID: ProductID;
    readonly name: string;
    readonly code?: string;
    readonly features: Array<string>;
}

export const PackageSelector: React.FC<IProps> = ({ onSelectProduct, selectedProductID, packages }) => {
    const [t] = useTranslation();

    const styledPackageList = packages.map((p) => {
        const isProductSelected = selectedProductID === p.productID;
        const onSelect = async () => {
            onSelectProduct(p.productID);
            await telemetry.Log(TelemetryEvent.ProductSelected, p.productID);
        };

        const featureList = p.features.map((feature) => (
            <li key={feature} className="flex gap-x-3">
                <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                <span className="text-gray-900">{feature}</span>
            </li>
        ));
        return (
            <div
                key={p.productID}
                className={classNames(isProductSelected ? "ring-2 ring-indigo-600" : "ring-1 ring-gray-200", "rounded-3xl p-8")}
            >
                <h3
                    id={p.productID}
                    className={classNames(isProductSelected ? "text-indigo-600" : "text-gray-900", "text-lg font-semibold leading-8")}
                >
                    {p.name}
                </h3>
                <p className="mt-6 flex items-baseline gap-x-1">
                    <Prices productID={p.productID} discountRate={0} showTextOnly={true} />
                </p>
                <a
                    aria-describedby={p.productID}
                    onClick={onSelect}
                    className={classNames(
                        isProductSelected
                            ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                            : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                        "mt-6 cursor-pointer block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                >
                    {t("createJobPage.stage4.select")}
                </a>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                    {featureList}
                </ul>
            </div>
        );
    });
    return (
        <>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Select a package that works for you
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">Choose from one of the following</p>
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                        {styledPackageList}
                    </div>
                </div>
            </div>
        </>
    );
};
