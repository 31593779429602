import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SingleSelector } from "./SingleSelector";

interface IProps {
    readonly imageKeys: Array<string>;
}

export const MultiSelector: React.FC<IProps> = ({ imageKeys }) => {
    const [t] = useTranslation();
    const selectors = useMemo(
        () =>
            imageKeys.map((key, index) => {
                return (
                    <SingleSelector
                        key={key}
                        imageKey={key}
                        uploadTitle={t("createJobPage.stage2.uploadSource", { faceNumber: index + 1 })}
                        uploadLabel={t("createJobPage.stage2.uploadFaceLabel", { faceNumber: index + 1 })}
                    />
                );
            }),
        [imageKeys, t]
    );

    return <>{selectors}</>;
};
